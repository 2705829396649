import { cast, flow, types } from "mobx-state-tree";
import { pullUser } from "../api";
import { ApiResponse } from "apisauce";

const User = types.model({
  username: types.identifier,
  name: types.string,
});

export const UserStore = types
  .model({
    currentUser: types.maybeNull(User),
  })
  .actions((self) => ({
    pullUser: flow(function* () {
      const response: ApiResponse<any> = yield pullUser();
      if (response.problem) {
        return;
      }
      self.currentUser = cast(response.data);
    }),
  }));
