import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import { observer } from "mobx-react-lite";

const IndexPage = loadable(() => import("../pages/Index"));
const RegisterPage = loadable(() => import("../pages/Register"));
const LoginPage = loadable(() => import("../pages/Login"));
const Page = loadable(() => import("../pages/Page"));

function MainRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/page/edit/:id" element={<Page />} />
      </Routes>
    </Router>
  );
}

export default observer(MainRoutes);
