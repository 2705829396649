import devConfig from "./dev";

interface ConfigInterface {
  API_PATH: string;
}

let config = {} as ConfigInterface;

if (process.env.NODE_ENV === "production") {
} else {
  config = { ...config, ...devConfig };
}

export default config;
