import config from "../config/index";
import { commonStore } from "../singletons/stores";
import { create } from "apisauce";

const api = create({
  baseURL: config.API_PATH,
  timeout: 10000,
});

api.addRequestTransform((request) => {
  if (!request.headers) return;
  if (commonStore.token)
    request.headers["Authorization"] = `Bearer ${commonStore.token}`;
});

export interface LoginInterface {
  username: string;
  password: string;
}
export const login = ({ username, password }: LoginInterface) =>
  api.post("/users/login/token", { username, password });

export interface AddPageContentInterface {
  pageName: string;
  content_type: string;
  content: {};
}

export const addPageContent = ({
  pageName,
  content_type,
  content,
}: AddPageContentInterface) =>
  api.post("/pages/page-content/", { page: pageName, content_type, content });

export const pullUser = () => api.get("/users/pull");
