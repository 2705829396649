import { types } from "mobx-state-tree";

const CommonStore = types
  .model({
    token: types.maybeNull(types.string),
    appLoaded: false,
  })
  .actions((self) => ({
    afterCreate: () => {
      self.token = window.localStorage.getItem("jwt");
    },

    setToken: (token: string) => {
      self.token = token;
      window.localStorage.setItem("jwt", token);
    },
  }));

export default CommonStore;
