import { flow, types } from "mobx-state-tree";
import { addPageContent, AddPageContentInterface } from "../api";
import { ApiResponse } from "apisauce";

const PageContentStore = types
  .model({
    error: "",
    loading: false,
    success: false,
  })
  .actions((self) => ({
    addPageContent: flow(function* (addData: AddPageContentInterface) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield addPageContent(addData);

        if (response.problem) {
          // Handle errors here
          return;
        }
        self.success = true;
      } finally {
        self.loading = false;
      }
    }),
    clearSuccess: () => {
      self.success = false;
    },
  }));

export default PageContentStore;
