import { flow, getEnv, types } from "mobx-state-tree";
import { login, LoginInterface } from "../api";
import { ApiResponse } from "apisauce";

const AuthStore = types
  .model({
    error: "",
    loading: false,
  })
  .actions((self) => ({
    login: flow(function* (props: LoginInterface) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield login(props);
        if (response.problem) {
          self.error = response.problem;
          return;
        }
        getEnv(self).commonStore.setToken(response.data.access);
      } finally {
        self.loading = false;
      }
    }),
  }));

export default AuthStore;
