import React, { useEffect } from "react";
import "./App.css";
import MainRoutes from "./routes/MainRoutes";
import { useStores } from "./hooks/useStores";
import { observer } from "mobx-react-lite";

function App() {
  const { commonStore, userStore } = useStores();

  useEffect(() => {
    if (!commonStore.token) return;
    userStore.pullUser();
  }, [commonStore.token, userStore]);

  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default observer(App);
